.breadcumb-menu {
  max-width: 100%;
  margin: -0.35em 0 -0.35em 0;
  padding: 0;
  list-style-type: none;

  li {
    display: inline-block;
    list-style: none;
    position: relative;

    &:after {
      content: "|";
      position: relative;
      margin: 0 17px 0 20px;
      @include respond-below(sm) {
        margin: 0 8px 0 10px;
      }
    }

    &:last-child {
      &:after {
        display: none;
      }
    }

    // &:last-child {
    //   &,
    //   a,
    //   span {
    //     color: $theme-color;
    //   }
    // }
  }

  li,
  a,
  span {
    white-space: normal;
    word-break: break-word;
    font-weight: 700;
    font-size: 24px;
    font-family: var(--title-font);
    color: var(--white-color);
    @include respond-below(md) {
      font-size: 20px;
    }
    @include respond-below(sm) {
      font-size: 18px;
    }
  }
  a {
    position: relative;
    &::before {
      position: absolute;
      content: "";
      width: 0%;
      height: 1px;
      background-color: var(--white-color);
      bottom: -2px;
      right: 0;
      transition: all 0.3s ease-in-out;
    }
    &:hover {
      &::before {
        width: 100%;
        left: 0;
      }
    }
  }
}

.breadcumb-title {
  color: $white-color;
  margin: -0.3em 0 -0.35em 0;
  max-width: 60%;
  width: 100%;
  @include respond-below(lg) {
    max-width: 50%;
  }
}

.breadcumb-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0 40px;
}

.breadcumb-wrapper {
  padding: 223px 0;
  background-color: var(--title-color);
  position: relative;
  overflow: hidden;
  .shape {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    height: 100%;
    @include respond-below(md) {
      display: none;
    }
  }
  .overlay {
    background-color: var(--title-color);
    opacity: 0.6;
  }

  @include md {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}

@include md {
  .breadcumb-content {
    display: block;
  }

  .breadcumb-title {
    max-width: 100%;
  }

  .breadcumb-menu {
    margin-top: 15px;
  }
}

.offer-counter {
  display: flex;
  align-items: center;
  list-style: none;
  text-align: center;
  padding-left: 0;
  margin-bottom: 0;
  gap: 10px;
  li {
    background: #ffffff;
    box-shadow: 0px 0px 13px rgba(116, 9, 246, 0.07);
    border-radius: 10px;
    min-width: 90px;
  }
  .count-name {
    display: block;
    font-size: 16px;
    font-weight: 700;
    color: var(--theme-color);
    padding: 6px 0;
    border-top: 2px solid var(--smoke-color);
    line-height: 1;
  }
  .count-number {
    font-size: 30px;
    font-weight: 700;
    padding: 29px 0;
  }
}
