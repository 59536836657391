.event {
  &-style1,
  &-style2,
  &-style3 {
    margin-bottom: 30px;
    border-radius: 10px;
    overflow: hidden;
    .event {
      &-img {
        position: relative;
      }
      &-tags {
        position: absolute;
        top: 30px;
        left: 30px;
        display: flex;
        align-items: center;
        gap: 5px;
        flex-wrap: wrap;
        a {
          display: inline-block;
          padding: 5px 20px;
          border-radius: 30px;
          background-color: var(--theme-color);
          color: var(--white-color);
          font-size: 14px;
          font-weight: 700;
        }
      }
      &-date {
        position: absolute;
        bottom: 30px;
        right: 30px;
        text-align: center;
        background: var(--theme-color);
        padding: 13px 9px;
        border-radius: 10px;
        color: var(--white-color);
        span {
          display: block;
          font-size: 20px;
          font-weight: 700;
          line-height: 1;
          margin-bottom: 2px;
        }
      }
      &-meta {
        margin-bottom: 16px;
        ul {
          padding-left: 0;
          margin-bottom: 0;
          list-style: none;
          display: flex;
          gap: 30px;
          flex-wrap: wrap;
          row-gap: 0;
        }
        span {
          display: inline-flex;
          align-items: center;
          gap: 10px;
          font-size: 14px;
          i {
            font-size: 14px;
            color: var(--theme-color);
          }
        }
      }
      &-content {
        padding: 34px 30px 40px;
        border: 2px solid var(--border-color);
        border-top: 0;
        border-radius: 0 0 10px 10px;
        @include respond-below(xl) {
          padding: 35px 25px 35px;
        }
        @include respond-below(lg) {
          padding: 30px 20px 30px;
        }
      }
      &-title {
        margin-bottom: 10px;
        a {
          display: block;
          color: var(--title-color);
          &:hover {
            color: var(--theme-color);
          }
        }
      }
      &-text {
        margin-bottom: 15px;
      }
      &-link {
        display: inline-block;
        font-weight: 700;
        color: var(--title-color);
        padding: 5px 20px;
        border: 2px solid var(--theme-color);
        border-radius: 30px;
        &:hover {
          background-color: var(--theme-color);
          color: var(--white-color);
        }
      }
      &-footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      &-price {
        font-size: 30px;
        font-weight: 700;
        line-height: 40px;
        gap: 5px;
        @include respond-below(xl) {
          font-size: 25px;
        }
        @include respond-below(lg) {
          font-size: 16px;
        }
        span {
          color: var(--theme-color);
        }
      }
      &-text {
        margin-bottom: 23px;
      }
    }
  }
  &-style1 {
    .event-img {
      & > img {
        width: 100%;
      }
    }
    .event-title {
      @include respond-below(md) {
        font-size: 18px;
      }
    }
  }
  &-style2 {
    position: relative;
    overflow: visible;
    height: 320px;
    margin-bottom: 70px;
    @include respond-below(sm) {
      height: auto;
      margin-bottom: 30px;
    }
    .event-content {
      position: absolute;
      bottom: -70px;
      left: 30px;
      min-width: 420px;
      background-color: var(--white-color);
      border: 2px solid var(--border-color);
      border-radius: 10px;
      opacity: 0;
      visibility: hidden;
      transition: all 0.3s ease-in-out;
      z-index: 1;
      width: 420px;
      @include respond-below(sm) {
        position: static;
        width: 100%;
        min-width: 100%;
      }
    }
    .event-img {
      position: relative;
      overflow: hidden;
      height: 320px;
      .overlay {
        background: linear-gradient(
          180deg,
          #272e39 0%,
          rgba(39, 46, 57, 0) 100%
        );
        opacity: 0.7;
        border-radius: 10px;
      }
      .img {
        border-radius: 10px;
        height: 320px;
        object-fit: cover;
        @include md {
          width: 100%;
        }
      }
      .event-title {
        position: absolute;
        bottom: 0;
        left: 0;
        margin-bottom: 0;
        padding: 30px;
        font-size: 20px;
        @include smooth-transition;
        a {
          color: var(--white-color);
        }
      }
    }
    .event-date {
      bottom: auto;
      top: 30px;
    }
  }
  &-style3 {
    .event-title {
      font-size: 30px;
    }
    .event-content {
      padding: 34px 40px 40px;
    }
    .event-footer {
      gap: 30px;
    }
  }
}
.events-layout2 {
  background-color: var(--smoke-color);
  padding-top: 230px;
  margin-top: -110px;
  padding-bottom: 120px;
  @include respond-below(sm) {
    padding-top: 190px;
    padding-bottom: 50px;
  }
}

.events-slide2 {
  .slick-slide {
    @include smooth-transition;
  }
  .slick-slide.slick-current.slick-active.slick-center,
  .slick-slide.slick-current.slick-active {
    // width: 584px !important;
    .event-title {
      opacity: 1;
      @include smooth-transition;
    }
    .event-content {
      opacity: 1;
      visibility: visible;
      @include smooth-transition;
    }
    .event-img {
      .event-title {
        opacity: 0;
      }
    }
    .event-date {
      bottom: auto;
      top: 30px;
      z-index: 9;
      right: 30px;
    }
  }
}

.event-title-count {
  margin-bottom: 0;
  font-weight: 700;
}

.event-tab {
  .nav-tabs {
    border-bottom: 0;
    gap: 20px;
  }
  .nav-tabs .nav-link {
    border: 0;
    padding: 0;
    border-radius: 0;
    font-size: 30px;
    background-color: transparent;
    color: var(--body-color);
    &.active {
      color: var(--theme-color);
    }
  }
}

.event-details-img {
  border-radius: 30px;
}
.event-details-img2 {
  border-radius: 20px;
}

.share-links-title {
  font-weight: 700;
  margin-right: 3px;
}

.social-links {
  padding: 0;
  margin: 0;
  list-style-type: none;
  display: inline-flex;

  li {
    display: inline-block;
    margin-right: 13px;

    &:last-child {
      margin-right: 0;
    }
  }

  a {
    line-height: 1;
    font-size: 16px;
    color: $body-color;
    text-align: center;
    display: block;

    &:hover {
      color: $theme-color;
    }
  }
}

.item-card {
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 20px;
  border-radius: 20px;
  background-color: var(--smoke-color);
  .item-icon {
    min-width: 60px;
    height: 60px;
    background: var(--theme-color);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: var(--white-color);
    font-size: 27px;
    border-radius: 10px;
  }
  .item-title {
    display: block;
    font-weight: 700;
  }
  .item-text {
    font-size: 20px;
    font-weight: 700;
    color: var(--title-color);
  }
}

.event-timeline {
  padding: 0 113px;
  margin: 0 0 38px 0;
  &__bar {
    position: relative;
    display: block;
    width: 100%;
    margin-bottom: 0px;
    margin-right: auto;
    margin-left: auto;
    .timeline-line {
      position: absolute;
      width: 106%;
      height: 4px;
      background-color: var(--sec-color);
      right: 0;
      left: 50%;
      transform: translateX(-50%);
      &::before,
      &::after {
        position: absolute;
        content: "";
        width: 20px;
        height: 20px;
        background-color: var(--theme-color);
        top: 50%;
        transform: translateY(-50%);
        border-radius: 50%;
        border: 5px solid var(--smoke-color);
      }
      &::after {
        left: auto;
        right: 0;
      }
    }
  }
}

.timeline-item {
  text-align: center;
  min-width: 138px;
  border: 2px solid var(--sec-color);
  border-radius: 10px;
  padding: 20px 0 12px 0;
  position: relative;
  margin: 39px 0 0 0;
  @include smooth-transition;
  &::before {
    position: absolute;
    content: "";
    width: 40px;
    height: 40px;
    top: -40px;
    left: 50%;
    transform: translateX(-50%);
    background: linear-gradient(180deg, #d3444b 0%, rgba(116, 9, 246, 0) 100%);
  }
  i {
    font-size: 24px;
    color: var(--title-color);
    margin-bottom: 12px;
    @include smooth-transition;
  }
  span {
    display: block;
    font-size: 20px;
    font-weight: 700;
    color: var(--title-color);
    @include smooth-transition;
  }
  &:hover {
    background-color: var(--theme-color);
    border-color: var(--theme-color);
    i,
    span {
      color: var(--white-color);
    }
  }
}

.timeline-step {
  width: 138px;
  height: 40px;
  position: relative;
  text-align: center;
  margin-bottom: -20px;
  z-index: 1;
  &::before {
    position: absolute;
    content: "";
    width: 40px;
    height: 40px;
    background-color: var(--theme-color);
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    border: 10px solid var(--smoke-color);
  }
}

.ebooking-wrap1 {
  padding: 70px 0 40px;
}
.ebooking-wrap2 {
  margin-bottom: 70px;
}

.order-summmary {
  padding: 30px;
  border-radius: 20px;
  background-color: var(--smoke-color);
  table {
    width: 100%;
    border-collapse: collapse;
    overflow: hidden;
    border-radius: 20px;
    margin-bottom: 0;
    background-color: var(--white-color);
  }
  th,
  td {
    border: 0;
    border-bottom: 1px solid var(--border-color);
    padding: 17px;
    text-align: center;
  }
  tr:first-child,
  tr:first-child th {
    background-color: var(--sec-color);
    border-color: var(--sec-color);
    color: var(--white-color);
    text-align: center;
  }
  tr:last-child,
  tr:last-child td {
    background-color: var(--theme-color);
    border-color: var(--theme-color);
    color: var(--white-color);
  }
}
